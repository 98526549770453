.pageBody {
    margin: 30px;
}

.videos {
    display: block;
}

@media screen and (min-width: 1000px) {
    .pageBody {
        margin: 30px;
    }
    
    .videos {
        display: grid;
        grid-template-columns: auto auto;
    }
}