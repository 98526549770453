.thumbnailImage img {
    width: 336px;
    height: 189px;
}

.embedVideo {
    width: 336px;
    height: 189px;
}


@media screen and (min-width: 620px) {
    .thumbnailImage img {
        width: 560px;
        height: 315px;
    }
    
    .embedVideo {
        width: 560px;
        height: 315px;
    }
}

@media screen and (min-width: 1000px) {
    .thumbnailImage img {
        width: 448px;
        height: 252px;
    }
    
    .embedVideo {
        width: 448px;
        height: 252px;
    }
}

@media screen and (min-width: 1200px) {
    .thumbnailImage img {
        width: 560px;
        height: 315px;
    }
    
    .embedVideo {
        width: 560px;
        height: 315px;
    }
}